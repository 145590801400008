<svg class="lds-dash-ring" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"
  style="background: none; shape-rendering: auto;">
  <g class="" transform="rotate(41.9119 50 50)">
    <animateTransform attributeName="transform" type="rotate" values="0 50 50;120 50 50" keyTimes="0;1" dur="0.5s"
      repeatCount="indefinite" class=""></animateTransform>
    <circle cx="50" cy="50" r="40" stroke="#ff1a56" fill="none" stroke-dasharray="41.88790204786391 251.32741228718345"
      stroke-linecap="round" stroke-width="5" transform="rotate(0 50 50)" class="">
      <animate attributeName="stroke" values="#ff1a56;#ffffff" keyTimes="0;1" dur="0.5s" repeatCount="indefinite"
        class=""></animate>
    </circle>
    <circle cx="50" cy="50" r="40" stroke="#ffffff" fill="none" stroke-dasharray="41.88790204786391 251.32741228718345"
      stroke-linecap="round" stroke-width="5" transform="rotate(120 50 50)" class="">
      <animate attributeName="stroke" values="#ffffff;#ff1a56" keyTimes="0;1" dur="0.5s" repeatCount="indefinite"
        class=""></animate>
    </circle>
    <circle cx="50" cy="50" r="40" stroke="#ff1a56" fill="none" stroke-dasharray="41.88790204786391 251.32741228718345"
      stroke-linecap="round" stroke-width="5" transform="rotate(240 50 50)" class="">
      <animate attributeName="stroke" values="#ff1a56;#ff1a56" keyTimes="0;1" dur="0.5s" repeatCount="indefinite"
        class=""></animate>
    </circle>
  </g>
</svg>