<div class="splash" [@splash]="showSplash ? hideSplash ? 'after' : 'during' : 'before'"> <!--showSplash ? 'shown' : 'hidden'-->
  <div class="pointy-bit"></div>
  <div class="exity-bit">
    <div class="exity-circle"></div>
  </div>
  <!-- <div class="splash-content-gray" [@splashContentGray]="showSplashGray ? 'shown' : 'hidden'"></div>
  <div class="splash-content-primary" [@splashContentPrimary]="showSplashPrimary ? 'shown' : 'hidden'"></div> -->
</div>

<!-- <div class="splash-exit" [@splashExit]="showSplashExit ? 'shown' : 'hidden'">
  <div class="circle"></div>
</div> -->

<div class="logo" *ngIf="showLogo" [@splashLogo]>
  <ng-lottie [options]="lottieConfig" [width]="logoSize" [height]="logoSize"></ng-lottie>
</div>