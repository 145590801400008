import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, Resolve } from '@angular/router';
import { Document } from 'prismic-javascript/types/documents';
import { PrismicService } from './prismic.service';
import { LanguageService } from './language.service';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root'
})
export class PrismicResolve implements Resolve<boolean | Document> {
  constructor(
    private router: Router,
    private prismic: PrismicService,
    private lang: LanguageService,
    private tracking: TrackingService
  ) {}

  async resolve(next: ActivatedRouteSnapshot) {
    const uid = next.paramMap.get('uid');
    const result = await this.prismic.getByUid(uid);
    if (!result) {
      const path = next.url.map(segment => segment.path).join('/');
      this.tracking.pageNotFound(path);
      this.router.navigate([this.lang.current, '404'], {
        queryParams: next.queryParams
      });
      return undefined;
    } else {
      return result;
    }
  }
}
