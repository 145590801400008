import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  rashjunior = { downloadLink: 'https://app.adjust.com/fp7mui6' };
  janosch = { downloadLink: 'https://app.adjust.com/56shk02' };
  bendrit = { downloadLink: 'https://app.adjust.com/bcack34' };
  noelia = { downloadLink: 'https://app.adjust.com/v16htel' };
  ivan = { downloadLink: 'https://app.adjust.com/4zc98pa' };
}
