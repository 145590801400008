<div class="form-and-loader-container">
  <div class="form-container">
    <form #signupForm="ngForm" (ngSubmit)="submit(signupForm)" [ngClass]="{submitted: signupForm.submitted}">
      <div class="align-button-and-input">
        <div class="form-group">
          <input type="tel" (blur)="blurMsisdn(signupForm)" id="msisdn" [(ngModel)]="msisdn" pattern="07[0-9]{8}"
            [ngClass]="{'not-empty': msisdn.length}" name="msisdn" required>
          <label for="msisdn">{{'MSISDN' | translate}}</label>
        </div>
        <button type="submit" class="button big bottomless" [ngClass]="buttonExtraClass"
          (click)="tracking.ctaClick('send download link')">{{ctaText}}</button>
      </div>
    </form>
  </div>
</div>
