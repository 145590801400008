<div class="cookie-banner-container" *ngIf="showCookieBannerContainer && showCookieBanner" [@cookieBanner]>
  <div class="cookie-banner">
    <div class="info">
      <span>{{'COOKIEBANNER1' | translate}}</span>&nbsp;<a routerLink="/{{lang.current}}/cookie-info">{{'COOKIEBANNER2' | translate}}</a>&nbsp;<span>{{'COOKIEBANNER3' | translate}}</span>
    </div>
    <div class="buttons">
      <button (click)="hideCookieBanner()" class="close-button" aria-label="close">
        <img src="/assets/icons/times.svg" alt="">
      </button>
    </div>
  </div>
</div>