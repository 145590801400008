import { Component, Output, EventEmitter, AfterViewInit } from '@angular/core';
import {
  trigger,
  transition,
  style,
  animate,
  state
} from '@angular/animations';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
  animations: [
    trigger('splash', [
      state('during', style({ transform: 'translateX(0)' })),
      state('before', style({ transform: 'translateX(200%)' })),
      state('after', style({ transform: 'translateX(-200%)' })),
      transition('before => during', animate('0.7s ease-in')),
      transition('during => after', animate('0.7s ease-in'))
    ]),
    trigger('splashLogo', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('250ms ease', style({ opacity: 1 })),
        animate('250ms ease', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class SplashComponent implements AfterViewInit {
  @Output() onDone = new EventEmitter();
  showSplash = false;
  hideSplash = false;
  showLogo = true;
  lottieConfig: AnimationOptions;
  public logoSize = window.innerWidth < 700 ? '500px' : '700px';

  constructor() {
    this.lottieConfig = {
      path: 'assets/lottie/Logo-Short.json',
      renderer: 'canvas',
      loop: false,
      autoplay: true
    } as any;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.showSplash = true;
      setTimeout(() => {
        this.hideSplash = true;
        this.showLogo = false;
        setTimeout(() => this.onDone.emit('done'), 750);
      }, 1850);
    });
  }
}
