import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, Resolve } from '@angular/router';
import { Document } from 'prismic-javascript/types/documents';
import { LanguageService } from './language.service';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root'
})
export class GdprResolve implements Resolve<boolean | Document> {
  constructor(
    private router: Router,
    private lang: LanguageService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    let lang = route.paramMap.get('lang');
    if (!lang) {
      lang = this.lang.current;
    }

    const token = route.paramMap.get('token');

    if (!token) {
      window.open(`/ms/gdpr/${lang}`, '_self');
    } else {
      window.open(`/ms/gdpr/${lang}/unsubscribe?logintoken=${token}`, '_self');
    }

    return false;
  }
}
