import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  animations: [
    trigger('popUpInOut', [
      transition('* => void', [animate(200, style({ opacity: 0 }))]),
      transition('void => *', [
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class TooltipComponent implements OnInit {
  @Input() text: string;

  open: boolean;

  constructor() {}

  ngOnInit(): void {}
}
