<div *ngIf="remaining" class="container">
  <div *ngIf="remaining.days" class="group">
    <span class="label" translate="DAYS"></span>
    <span class="digits">{{ remaining.days }}</span>
  </div>
  <div *ngIf="remaining.days" class="separator">:</div>
  <div *ngIf="remaining.hours" class="group">
    <span class="label" translate="HOURS"></span>
    <span class="digits">{{ remaining.hours | number: '2.0-0' }}</span>
  </div>
  <div *ngIf="remaining.hours" class="separator">:</div>
  <div class="group">
    <span class="label" translate="MINUTES"></span>
    <span class="digits">{{ remaining.minutes | number: '2.0-0' }}</span>
  </div>
  <div class="separator">:</div>
  <div class="group">
    <span class="label" translate="SECONDS"></span>
    <span class="digits">{{ remaining.seconds | number: '2.0-0' }}</span>
  </div>
</div>
