import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { LanguageService } from './language.service';

/**
 * Navigates to the current language.
 */
@Injectable()
export class LangaugeResolve implements Resolve<string> {
  constructor(private router: Router, private lang: LanguageService) {}

  resolve(next: ActivatedRouteSnapshot): any {
    this.router.navigate([this.lang.current], {
      replaceUrl: true,
      queryParams: next.queryParams
    });
  }
}
