import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  public menuActive = false;
  public routerShown = false;
  observer: Subscriber<unknown>;

  public showRouter = new Observable((observer) => {
    if (this.routerShown) {
      observer.next();
    }
    this.observer = observer;
  });

  public doShowRouter() {
    this.routerShown = true;
    if (this.observer) {
      this.observer.next();
    }
  }

}
